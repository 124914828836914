import React from "react"
import { Link, graphql } from "gatsby";
import { useIntl, FormattedMessage, Link as IntlLink } from "gatsby-plugin-intl"
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLightbulb, faShoppingBasket, faUniversity, faLaptopCode } from '@fortawesome/free-solid-svg-icons'

import Layout from "../components/layout"
import SEO from "../components/seo"
import image from "../images/safar-safarov-MSN8TFhJ0is-unsplash.jpg";

import './index.css';

library.add(faLightbulb, faShoppingBasket, faUniversity, faLaptopCode);

const IndexPage = ({ data }) => {
  const intl = useIntl()
  const services = data.allMarkdownRemark.edges;
  return (
    <Layout hero={{
      image, tagline: intl.formatMessage({ id: "home-tagline" }),
      image_attribution: {user: 'codestorm', name: 'Safar Safarov'}
    }}>
      <SEO title={intl.formatMessage({ id: "home-title" })}/>
      <h2 className="home-header">
        <FormattedMessage id="home-needs" />
      </h2>
      <ul className="card-list">
        {services.map(service => {
          const fm = service.node.frontmatter;
          return (
            <Link
              key={fm.slug}
              to={`/${fm.source_language}/${fm.type}/${fm.slug}`}
            >
              <li className="card" style={{background: fm.link.color}}>
                <FontAwesomeIcon icon={fm.link.icon} size="4x"/>
                <p>
                  {fm.link.text}
                </p>
              </li>
            </Link>
          )
        })}
      </ul>
      <div className="cta">
        <IntlLink
          className="cta-button"
          to="/contact"
        >
          <FormattedMessage
            id="post-contact-cta"
          />
        </IntlLink>
      </div>
    </Layout>
  );
}

export default IndexPage

export const pageQuery = graphql`
  query($language: String!) {
    allMarkdownRemark(
      filter: {
        frontmatter: {
          type: {eq: "service"}
          source_language:  {eq: $language}
        }
      },
      sort: {fields: frontmatter___link___weight}
    ) {
      edges {
        node {
          frontmatter {
            source_language
            type
            slug
            link {
              color
              icon
              text
            }
          }
        }
      }
    }
  }
`
